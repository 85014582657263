






















































import { Toast } from "vant"
import HelpComment from "@/components/HelpComponent/HelpSuc/HelpComment.vue"
import { HelpSucType,HelpCommentType } from "@/Type/index"
import Stroage from "@/util/Storage" 
import { Vue,Component,Prop, Emit } from "vue-property-decorator"

interface HelpSucCommentType<T=string>{
    loadingShow:boolean;
    userId:T|number;
    token:T;
    DefaultIcon:T;
    ChatIcon:T;
    DelIcon:T;
    Show:boolean;
    CommentData:{ helpId?:T|number;toId?:T|number; }

    handleClickComment():void;
    handleComment(data:HelpCommentType):void;
    handleRemoveBtn(data:HelpCommentType):void;
    handleChangeShow():void;
    handleFilterTime(time:string):string;
}

@Component({ name:"HelpSucComment",components:{ HelpComment } })
export default class HelpSucComment extends Vue implements HelpSucCommentType{
    loadingShow = false
    userId:string|number = ""
    token = ""
    DefaultIcon = require("$icon/Basics/default.png");
    ChatIcon = require("$icon/Hand/Chat.png");
    DelIcon = require("$icon/Hand/Del.png");
    Show = false;
    CommentData = {}

    mounted() {
       this.userId = Stroage.GetData_ && Stroage.GetData_("userId")
       this.token = Stroage.GetData_ && Stroage.GetData_("token")    
    }

    handleClickComment(){
        let data = {
            helpId:this.GetData.id,
            toId:this.GetData.userId
        }
        this.CommentData = data
        this.handleChangeShow()
    }

    handleComment(data:HelpCommentType){
        this.CommentData = {
            helpId:data.helpAllId,
            toId:data.fromUserId
        }
        this.handleChangeShow()
    }

    handleRemoveBtn(data:HelpCommentType){
        Toast({
           message:"暂未开放功能",
           icon:"none",
           className:"ToastClassName"
        })
    }

    handleChangeShow(){
        this.Show = !this.Show
    }

    handleFilterTime(time:string){
        return time.split("T")[0] as string
    }

    get GetList(){
        if( this.GetData.helpAllMessageDtoList?.length ){
            return this.GetData.helpAllMessageDtoList
        }else{
            return []
        }
    }

    @Prop(Object)
    data!:HelpSucType

    get GetData(){
        return this.data
    }

    @Emit("PullReftch")
    PullReftch(){
        return true
    }
}
