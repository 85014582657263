









































import { Notify,Dialog,Toast } from "vant"
import { HelpSucType } from "@/Type/index"
import Stroage from "@/util/Storage" 
import { BackHelpOrder,BackPay } from "@/Api/Help/index"
import { Vue,Component,Prop, Emit } from "vue-property-decorator"

interface HelpSucContType<T=string>{
    loadingShow:boolean;
    userId:T|number;
    token:T;
    defaultIcon:T;
    TestIcon:T;
    
    handleBackOrder():void;
    handleBackPay():void;
    handleError(data:string):void;
}

@Component({ name:"HelpSucCont" })
export default class HelpSucCont extends Vue implements HelpSucContType{
    loadingShow = false
    userId:string|number = ""
    token = ""
    defaultIcon = require("$icon/Basics/default.png")
    TestIcon = require("$icon/Shop/TestShop.png");

    get GetImgList(){
        if( this.GetData.picture?.length ){
           return this.GetData.picture?.split(",")
        }else{
            return []
        }
    }

    mounted() {
       this.userId = Stroage.GetData_ && Stroage.GetData_("userId")
       this.token = Stroage.GetData_ && Stroage.GetData_("token")
    }

    handleBackOrder(){
        new Promise( (reslove,reject)=>{
           Dialog.confirm({
               title: '温馨提示',
               message: '尊敬的用户您确定要 取消订单么？',
               confirmButtonText:"确定取消",
               cancelButtonText:"考虑一下",
               className:"HideClassName",
               overlayClass:"MaskHideClassName"
           }).then( (res)=>{
               reslove(true)
           } ).catch(cat=>{
               reject()
           })
       } ).then(res=>{
           this.loadingShow = true
           BackHelpOrder({
               userId:this.userId,
               token:this.token
           },{
               id:this.GetData.id as string
           }).then(res=>{
               this.loadingShow = false
               if( res.message.code === "200" ){
                   Toast({
                      message:"取消订单成功",
                      icon:"none",
                      className:"ToastClassName"
                  })
                   this.ReftchData()
               }else{
                   this.handleError( res.message.msg )
               }
           })
       })
    }

    handleBackPay(){
        new Promise( (reslove,reject)=>{
           Dialog.confirm({
               title: '温馨提示',
               message: '尊敬的用户您确定要 退款么？',
               confirmButtonText:"确定退款",
               cancelButtonText:"考虑一下",
               className:"HideClassName",
               overlayClass:"MaskHideClassName"
           }).then( (res)=>{
               reslove(true)
           } ).catch(cat=>{
               reject()
           })
       } ).then(res=>{
           this.loadingShow = true
           BackPay({
               userId:this.userId,
               token:this.token
           },{
               orderId:this.GetData.orderId as string
           }).then(res=>{
               this.loadingShow = false
               if( res.message.code === "200" ){
                   Dialog.confirm({
                       title: '退款成功',
                       message: '尊敬的用户！请及时查看微信退款,如有疑问请联系我',
                       confirmButtonText:"知道了",
                       showCancelButton:false,
                       className:"HideClassName",
                       overlayClass:"MaskHideClassName"
                   }).then(res=>res).catch(cat=>cat)
                   this.ReftchData()
               }else{
                   this.handleError( res.message.msg )
               }
           })
       })
    }


    handleError( data:string ){
       let str = ""
       if ( data.length ){
           str = data
       }else{
           str = "网络繁忙..."
       }
       Notify({
           message: str,
           color: 'white',
           background: "rgba(0,0,0,.6)",
       });            
    }

    @Emit("ReftchData")
    ReftchData(){
        return true
    }

    @Prop(Object)
    data!:HelpSucType

    get GetData(){
        return this.data
    }
}
